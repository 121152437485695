var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"justify-center "},[_c('h2',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t(_vm.label))+" ")])]),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex justify-end my-4"},[(_vm.moveable && _vm.selected.length == 0)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.reorderDialog = true}}},[_vm._v(_vm._s(_vm.$t("Reoreder Exercises")))]):_vm._e(),(_vm.selected.length > 0)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("move exersices")))]):_vm._e()],1),_c('data-table',{attrs:{"headers":_vm.headers,"data":_vm.items,"loader":_vm.loader,"meta":_vm.meta,"expandable":_vm.expandable,"moveable":true},on:{"updatePage":function($event){return _vm.getData($event)}},scopedSlots:_vm._u([{key:"header:id",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("id"))+" ")]},proxy:true},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.copyId(item.serial)}}},[_vm._v("mdi-content-copy")]),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(item.serial))])]}},{key:"header:select",fn:function(){return [_c('v-row',{staticClass:"justify-center align-center"},[_c('v-checkbox',{attrs:{"color":"white","label":_vm.$t('select all')},on:{"change":_vm.selectAll},model:{value:(_vm.select_all),callback:function ($$v) {_vm.select_all=$$v},expression:"select_all"}})],1)]},proxy:true},{key:"select",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"justify-center"},[_c('v-checkbox',{attrs:{"value":item.id},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]}},{key:"expand",fn:function(ref){
var item = ref.item;
return [_c('showTest',{attrs:{"id":_vm.id,"excludeId":_vm.excludeId,"exersicesItem":item}})]}},{key:"expand-icon",fn:function(ref){
var data = ref.data;
return [_c('v-icon',{style:(data.expand == data.item.id
                ? "transform: rotate(180deg);transition: transform 0.3s ease"
                : ''),attrs:{"color":data.childrens ? 'grey' : 'primary'}},[_vm._v("mdi-arrow-down")])]}}])})],1)],1),_c('reorderDialog',{attrs:{"items":_vm.items,"value":_vm.reorderDialog,"loading":_vm.reorderLoading},on:{"reorder":_vm.reorder,"close":function($event){_vm.reorderDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }