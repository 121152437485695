<template>
  <div>
    <v-row class="justify-center ">
      <h2 class="primary--text">
        {{ $t(label) }}
      </h2>
    </v-row>
    <v-row>
      <v-col>
        <div class="d-flex justify-end my-4">
          <v-btn
            v-if="moveable && selected.length == 0"
            @click="reorderDialog = true"
            color="primary"
            >{{ $t("Reoreder Exercises") }}</v-btn
          >

          <v-btn v-if="selected.length > 0" @click="submit" color="primary">{{
            $t("move exersices")
          }}</v-btn>
        </div>
        <data-table
          @updatePage="getData($event)"
          :headers="headers"
          :data="items"
          :loader="loader"
          :meta="meta"
          :expandable="expandable"
          :moveable="true"
        >
          <template #header:id>
            {{ $t("id") }}
          </template>
          <template #id="{item}">
            <v-icon @click="copyId(item.serial)" color="primary"
              >mdi-content-copy</v-icon
            >
            <span class="mx-1">{{ item.serial }}</span>
          </template>

          <template #header:select>
            <v-row class="justify-center align-center">
              <v-checkbox
                color="white"
                @change="selectAll"
                v-model="select_all"
                :label="$t('select all')"
              >
              </v-checkbox>
            </v-row>
          </template>
          <template #select="{item}">
            <v-row class="justify-center">
              <v-checkbox :value="item.id" v-model="selected"> </v-checkbox>
            </v-row>
          </template>

          <template #expand="{item}">
            <showTest :id="id" :excludeId="excludeId" :exersicesItem="item" />
          </template>
          <template #expand-icon="{data}">
            <v-icon
              :style="
                data.expand == data.item.id
                  ? `transform: rotate(180deg);transition: transform 0.3s ease`
                  : ''
              "
              :color="data.childrens ? 'grey' : 'primary'"
              >mdi-arrow-down</v-icon
            >
          </template>
        </data-table>
      </v-col>
    </v-row>
    <reorderDialog
      @reorder="reorder"
      @close="reorderDialog = false"
      :items="items"
      :value="reorderDialog"
      :loading="reorderLoading"
    />
  </div>
</template>
<script>
import showTest from "./showTest.vue";
import { Exercise } from "@/store/exercise/add";
import reorderDialog from "../../Dashboard/schools/execises/reorderDialog.vue";
export default {
  props: [
    "items",
    "headers",
    "meta",
    "loader",
    "state",
    "label",
    "id",
    "excludeId",
    "expandable",
    "moveable",
  ],
  data() {
    return {
      Exercise,
      select_all: false,
      selected: [],
      page: 1,
      sortItem: {},
      reorderDialog: false,
    };
  },
  components: {
    showTest,
    reorderDialog,
  },

  methods: {
    async reorder(exercises) {
      let payload = {
        lesson_id: this.id,
        exercises: exercises,
      };
      await Exercise.reOrderExercises(payload);
      this.reorderDialog = false;
      this.getData(this.page);
    },
    copyId(id) {
      navigator.clipboard
        .writeText(id)
        .then(() => {
          this.$toast(
            this.$i18n.t("serial copied"),
            { timeout: 4000, rtl: true },
            "success"
          );
        })
        .catch((error) => {
          console.error("Failed to copy ID:", error);
          // You can display an error message or handle the error here
        });
    },
    getData(page) {
      this.page = page;
      this.$emit("updatePage", page);
    },
    submit() {
      this.$emit("submit", this.selected);
    },
    selectAll(e) {
      if (e) {
        this.items.forEach((item) => {
          this.selected.push(item.id);
        });
      } else {
        this.selected = this.selected.filter(
          (id) => !this.items.some((obj) => obj.id === id)
        );
      }
    },
  },
  computed: {
    values() {
      return [this.items, this.selected];
    },
    reorderLoading() {
      return Exercise.editState.loading;
    },
  },
  watch: {
    values: {
      deep: true,
      handler() {
        const allIdsIncluded = this.items.every((obj) =>
          this.selected.includes(obj.id)
        );
        if (allIdsIncluded && this.items.length != 0) {
          this.select_all = true;
        } else {
          this.select_all = false;
        }
      },
    },
    state(val) {
      if (val) this.selected = [];
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-input--selection-controls .v-label {
  color: white; /* Replace with your desired label color */
}
</style>
