<template>
  <v-card>
    <v-card-text>
      <v-row class="justify-center">
        <v-col lg="6">
          <v-autocomplete
            outlined
            :items="itemsBiggestExercise"
            :loading="getExercisesLoader"
            item-text="serial"
            item-value="id"
            :label="$t('exercise biggest')"
            v-model="biggestExercise.exercise_id"
            :menu-props="{ closeOnClick: true }"
            :search-input.sync="searchBigger"
          >
            <template #item="{ item }">
              <div>{{ item.name }}</div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col lg="6">
          <v-autocomplete
            outlined
            :items="itemsSmallerExercise"
            item-text="serial"
            item-value="id"
            :label="$t('exercise smaller')"
            v-model="SmallerExercise.exercise_id"
            :menu-props="{ closeOnClick: true }"
            :search-input.sync="searchSmaller"
            :loading="getExercisesLoader"
          >
            <template #item="{ item }">
              <div>{{ item.name }}</div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col lg="6">
          <v-text-field
            outlined
            autofocus
            v-model="decision_grade"
            :rules="rules"
            :label="$t('minimum mark')"
            :error-messages="decisionGradeErrorMessages"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        color="blue darken-1"
        :disabled="validate"
        :loading="loading"
        text
        @click="submit"
        >{{ $t("save") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { Exercise } from "@/store/exercise/add";
export default {
  data() {
    return {
      Exercise,
      searchBigger: null,
      searchSmaller: null,
      itemsBigger: [],
      itemsSmaller: [],
      dialog: false,
      items: Exercise.tableData.lessonData,
      decision_grade: this.exersicesItem.decision_grade,
      biggestExercise: {
        type: ">=",
        exercise_id: this.getWhenId(">="),
      },
      SmallerExercise: {
        type: "<",
        exercise_id: this.getWhenId("<"),
      },
      exercise_id: this.exersicesItem.id,
      lesson_id: this.id,
      searchHandler: "",
      filteredItems: [],
      decisionGradeErrorMessages: [],
      rules: [
        (value) => {
          const errors = [];

          if (value === "") {
            errors.push("الحقل مطلوب");
          } else if (value > this.exersicesItem.max_grade) {
            errors.push(
              "لايمكن ان تكون العلامة الفارقة اكبر من العلامة العظمى للتمرين"
            );
          }

          this.decisionGradeErrorMessages = errors;
          return errors.length === 0;
        },
      ],
      autocompleteKey: 0,
    };
  },
  props: {
    exersicesItem: {
      type: Object,
    },
    id: {
      type: Number,
    },
    excludeId: {
      type: Number,
    },
  },
  computed: {
    getExercisesLoader() {
      return Exercise.deleteState.loading;
    },
    loading() {
      return Exercise.exerciseprefState.loading;
    },
    bigestItemsLoader() {
      return Exercise.deleteState.loading;
    },
    validate() {
      if (this.decisionGradeErrorMessages.length > 0) return true;
      if (
        this.country_id == "" ||
        this.decision_grade == "" ||
        this.biggestExercise.exercise_id == "" ||
        this.SmallerExercise.exercise_id == ""
      ) {
        return true;
      }
      return false;
    },
    itemsBiggestExercise() {
      const SmallerExerciseId = this.SmallerExercise.exercise_id;
      return this.itemsBigger.filter(
        (exercise) =>
          exercise.id !== SmallerExerciseId && exercise.id !== this.exercise_id
      );
    },
    itemsSmallerExercise() {
      const biggestExerciseId = this.biggestExercise.exercise_id;
      return this.itemsSmaller.filter(
        (exercise) =>
          exercise.id !== biggestExerciseId && exercise.id !== this.exercise_id
      );
    },
  },
  methods: {
    customFilter(item, queryText, itemText) {
      console.log("item", item, "queryText", queryText, "itemText", itemText);
    },

    submit() {
      const data = {
        exercises: [
          {
            exercise_id: this.biggestExercise.exercise_id,
            when: this.biggestExercise.type,
          },
          {
            exercise_id: this.SmallerExercise.exercise_id,
            when: this.SmallerExercise.type,
          },
        ],
        decision_grade: this.decision_grade,
        lesson_id: this.lesson_id,
      };
      Exercise.postExercisePreference(data, this.exercise_id);
    },
    removeDiacritics(text) {
      const diacriticsRegex = /[\u0610-\u061A\u064B-\u065F\u0670\u0640]/g;
      return text.replace(diacriticsRegex, "");
    },
    // delayedSearchHandler(items) {
    //   // setTimeout(() => {
    //   const searchTermWithoutDiacritics = this.removeDiacritics(
    //     this.searchHandler
    //   );
    //   items.filter((item) => {
    //     const normalizedItemName = this.removeDiacritics(item.name);
    //     item.normaliz = normalizedItemName;
    //     const items = normalizedItemName.includes(searchTermWithoutDiacritics);
    //     return items;
    //   });
    //   // }, 1000);
    // },
    getWhenId(type) {
      const items = this.exersicesItem.childrens;
      const filteredItems = items.filter((item) => {
        return item.when.includes(type);
      });
      const result =
        filteredItems.length > 0 ? filteredItems[0].exercise_id : "";
      return result;
    },
  },

  watch: {
    searchBigger(val) {
      if (val == "" || val == null) return;
      if (this.itemsBigger[0] && val == this.itemsBigger[0].serial) return;
      Exercise.getBySerial(
        { serial: val, exclude_lesson_id: this.excludeId },
        {
          onSuccess: (value) => {
            this.itemsBigger = value.exercises;
          },
        }
      );
    },
    searchSmaller(val) {
      if (val == "" || val == null) return;
      if (this.itemsSmaller[0] && val == this.itemsSmaller[0].serial) return;

      Exercise.getBySerial(
        { serial: val, exclude_lesson_id: this.excludeId },
        {
          onSuccess: (value) => {
            this.itemsSmaller = value.exercises;
          },
        }
      );
    },
    exersicesItem(val) {
      if (val.childrens) {
        this.itemsBigger = val.childrens.filter((item) => item.when == ">=");
      }
    },
  },
  mounted() {
    if (this.exersicesItem.childrens) {
      this.itemsBigger = this.exersicesItem.childrens.filter(
        (item) => item.when == ">="
      );
      if (this.itemsBigger[0])
        this.itemsBigger[0].id = this.itemsBigger[0].exercise_id;

      this.itemsSmaller = this.exersicesItem.childrens.filter(
        (item) => item.when == "<"
      );
      if (this.itemsSmaller[0])
        this.itemsSmaller[0].id = this.itemsSmaller[0].exercise_id;
    }
  },
};
</script>
