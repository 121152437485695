<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="800px"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title>
          <span>{{ $t("Reoreder Exercises") }}</span>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <thead class="text-center  primary">
              <tr>
                <th class="text-center white--text">
                  {{ $t("name") }}
                </th>
                <th class="text-center  white--text">
                  {{ $t("id") }}
                </th>
                <th class="text-center  white--text">
                  {{ $t("order") }}
                </th>
                <th></th>
              </tr>
            </thead>
            <draggable v-model="itemsCopy" tag="tbody">
              <tr
                class="text-center"
                v-for="item in itemsCopy"
                :key="item.name"
              >
                <td>{{ item.name }}</td>
                <td>{{ item.serial }}</td>
                <td>{{ item.order }}</td>
                <td><v-icon>mdi-unfold-more-horizontal</v-icon></td>
              </tr>
            </draggable>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('close')">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :disabled="disabled"
            :loading="loading"
            @click="reorder"
            color="blue darken-1"
            text
          >
            {{ $t("submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import draggable from "vuedraggable";

export default {
  props: {
    items: {
      default: [],
    },
    value: {
      default: false,
    },
    loading: {
      default: false,
    },
  },

  components: {
    draggable,
  },
  data: () => ({
    dialog: false,
    itemsCopy: [],
  }),
  methods: {
    reorder() {
      this.$emit(
        "reorder",
        this.itemsCopy.map((obj, index) => ({
          exercise_id: obj.id,
          order: index,
        }))
      );
    },
    compareLists(list1, list2) {
      if (list1.length !== list2.length) return false;

      const sortedList1 = [...list1];
      const sortedList2 = [...list2];
      for (let i = 0; i < sortedList1.length; i++) {
        if (sortedList1[i].id != sortedList2[i].id) {
          return false;
        }
      }
      return true;
    },
  },

  computed: {
    disabled() {
      return this.compareLists(this.items, this.itemsCopy);
    },
  },

  watch: {
    value(val) {
      if (val) this.itemsCopy = [...this.items];
      this.dialog = val;
    },
  },
};
</script>
